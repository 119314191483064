import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/layouts/Chooser.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Conditions Générales de Vente (Acheteurs)`}</h2>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`Dernière actualisation : 4 novembre 2021`}</em></p>
    </blockquote>
    <h3>{`Article 1. Identification`}</h3>
    <p>{`Les présentes Conditions Générales (ci-après les « CG ») sont proposées par ENIA, société par actions simplifiée au capital de 1090,80 €,
immatriculée au RCS de Nanterre sous le numéro 878 058 346 dont le siège social est sis au 11 rue Rouget de Lisle, 92600 Asnières sur Seine
(ci-après « ENIA »).`}</p>
    <p>{`ENIA est propriétaire de la Plateforme accessible via l’adresse URL `}<a parentName="p" {...{
        "href": "http://www.enia.green"
      }}>{`www.enia.green`}</a>{` (ci-après la « Plateforme »).`}</p>
    <p>{`Le Site est hébergé par OVH et le Directeur de publication est Monsieur Jacques-Arthur de Saint Germain.`}</p>
    <h3>{`Article 2. Définitions`}</h3>
    <p>{`Dans les présentes, les termes ci-dessous ont la signification qui leur est ici donnée :`}</p>
    <p>{`Fournisseur de matières organiques aussi appelé Fournisseur : désigne une entreprise qui a recours à la Plateforme afin d’être mise en relation
avec un Acheteur pour lui fournir des matières organiques (déchets agricole, d’élevage, etc.). Le Fournisseur doit être inscrit sur la plateforme
pour y déposer ses Fiches Produits.`}</p>
    <p>{`Acheteur aussi appelé Méthaniseur : désigne une entreprise qui souhaite acquérir les Produits proposés par le Fournisseur.`}</p>
    <p>{`Produits : désigne les matières organiques recherchées par l’Acheteur sur la Plateforme.`}</p>
    <p>{`Fiches Produits : désigne les annonces postées par les Fournisseurs sur la Plateforme présentant les Produits mis en vente.
CG ou Contrat : désigne les présentes conditions générales.`}</p>
    <p>{`Contenu : désigne les éléments et informations insérés par le Fournisseur sur sa Fiche Produit ou par l’Acheteur sur sa Fiche. Chaque
Utilisateur reste propriétaire et responsable du Contenu qu’il insère.`}</p>
    <p>{`Parties : désigne dans les présentes ENIA et l’Acheteur.`}</p>
    <p>{`Plateforme : désigne la Plateforme technique proposée par ENIA via l’adresse URL `}<a parentName="p" {...{
        "href": "http://www.enia.green"
      }}>{`www.enia.green`}</a>{` afin de mettre en relation un Fournisseur et
un Acheteur.`}</p>
    <p>{`Service(s) : désigne les services d’exploitation de données, de mise en relation et toute prestation de services connexe (comptabilité, logistique,
etc.) proposés par ENIA sur la Plateforme.`}</p>
    <h3>{`Article 3. Objet`}</h3>
    <p>{`La Plateforme digitale ENIA est une place de marché en ligne dédiée à l’identification de matières organiques des campagnes et la mise en
relation entre des Fournisseurs de matières organiques (déchet agricole, d’élevage, etc.) et des Acheteurs souhaitant capter les gisements de
matières organiques autour d’eux (méthaniseur, producteur de biogaz).`}</p>
    <p>{`Le Fournisseur s’inscrit sur la Plateforme, rédige une Fiche Produit et publie une description des Produits. Grâce à la Plateforme, le Fournisseur
va pouvoir améliorer ses revenus, récupérer du digestat ou de la chaleur, libérer de l’espace de stockage, se connecter à un réseau territorial
agricole et être mis en relation avec des Acheteurs intéressés par ses Produits.`}</p>
    <p>{`L’Acheteur s’inscrit sur la Plateforme dans le but de capter les gisements de matières organiques autour de lui et être mis en relation avec un
Fournisseur afin d’acquérir ses Produits. ENIA établit une cartographie des gisements de matières organiques disponibles dans le périmètre
d’un Acheteur selon différents critères (quantité, qualité, pouvoir méthanogène, mode de livraison, prix, etc.).
Grâce à la Plateforme, l’Acheteur va pouvoir augmenter son chiffre d’affaires, réduire ses coûts de productions et ruptures de stocks et améliorer
sa production grâce à la mise en relation permise par la Plateforme.`}</p>
    <p>{`ENIA en tant qu’intermédiaire ne fait que permettre la mise en relation entre le Fournisseur et l’Acheteur grâce à la Plateforme. ENIA n’est donc
pas responsable de la relation contractuelle entre le Fournisseur et l’Acheteur, ni des conséquences de celle-ci.`}</p>
    <p>{`L’Acheteur reconnaît avoir été suffisamment informé et que le Service proposé par ENIA lui convient.`}</p>
    <p>{`Toute utilisation de la Plateforme entraîne l’acceptation pleine et entière des présentes CG, CGU ainsi que des Politiques de confidentialité dont
le Fournisseur reconnaît avoir eu connaissance. Si l’Acheteur ne souhaite pas les accepter, il ne pourra pas utiliser la Plateforme.`}</p>
    <p>{`Chaque Acheteur s’engage à respecter lesdites CG lesquelles sont réputées avoir été acceptées avant toute inscription par le fait de cocher la
case « J’accepte sans réserve les Conditions Générales d’Utilisation et les Conditions Générales de Vente ENIA ». Le fait de cocher cette case
a la même valeur qu'une signature manuscrite de la part de l’Acheteur. Ces documents constituent les documents contractuels opposables aux
Parties, prévalent et annulent tout autre document. Ces CG sont mises à la disposition de l’Acheteur et sont consultables sur la Plateforme.`}</p>
    <p>{`ENIA conserve la possibilité de modifier à tout moment ces CG afin de respecter toute nouvelle réglementation. L’Acheteur qui ne souhaite pas
que les relations contractuelles soient régies par la nouvelle version devra le notifier à ENIA.`}</p>
    <h3>{`Article 4. Inscription sur la Plateforme – Vie du Compte`}</h3>
    <h4>{`4.1. Inscription et Utilisation de la Plateforme`}</h4>
    <p>{`Cf : `}<a parentName="p" {...{
        "href": "/legal/cgu"
      }}>{`CGU`}</a>{`.`}</p>
    <h4>{`4.2. Vie du Compte`}</h4>
    <p>{`L’inscription sur la Plateforme est valable pour une durée indéterminée.`}</p>
    <p>{`Le compte peut être fermé sur simple demande de l’Acheteur ou être fermé ou suspendu par ENIA en cas de non-respect par l’Acheteur de ses
obligations, de manquement aux CG, de plainte d’un Fournisseur. Quel que soit le cas de fermeture/ suspension, aucune indemnité ne sera versée
à l’Acheteur.`}</p>
    <p>{`Toutes les sommes dues par l’Acheteur à la date d’effet de la résiliation sont immédiatement exigibles.`}</p>
    <h4>{`4.3. Achat sur la Plateforme`}</h4>
    <p>{`L’Acheteur peut commander un Produit proposé tant que la Fiche Produit est visible sur la Plateforme. Il peut également publier une commande
libre. L’Acheteur valide sa commande et paye le Prix via la Plateforme et le module Stripe. Le paiement valide la Commande. La vente est
néanmoins soumise à la confirmation par le Fournisseur.`}</p>
    <h3>{`Article 5. Produits - Disponibilité`}</h3>
    <h4>{`5.1. Produits`}</h4>
    <p>{`Les Produits présentés sur la Plateforme sont présentés par le biais d’annonces, de Fiche Produit accompagnée des informations minimales
obligatoires, exactes et complètes transmises par le Fournisseur. Il s’agit d’un descriptif complet indiquant la catégorie, la nature du produit, la
quantité, la date de disponibilité du produit, le mode de livraison, le pouvoir méthanogène et tout autre caractéristique essentielle dudit produit.`}</p>
    <p>{`Les éventuelles photographies n’ont qu’une valeur indicative et non contractuelle.`}</p>
    <p>{`L’Acheteur assume la pleine responsabilité du choix des Produits qu’il commande en fonction de ses besoins et reconnaît avoir pris connais-
sance des caractéristiques des Produits.`}</p>
    <h4>{`5.2. Stock`}</h4>
    <p>{`Les Produits proposés sur la Plateforme sont valables dans la limite des stocks disponibles et tant qu’ils sont visibles sur la Plateforme.`}</p>
    <p>{`Après commande par l’Acheteur, le Fournisseur vérifie les stocks et confirme la vente. En cas d’indisponibilité du Produit commandé, le
Fournisseur ne confirme pas la vente et la vente est automatiquement résiliée et l’Acheteur est remboursé dans un délai raisonnable.`}</p>
    <h3>{`Article 6. Prix – Règlement`}</h3>
    <h4>{`6.1. Prix des Services`}</h4>
    <p>{`L’adhésion à la Plateforme est gratuite pour l’Acheteur.`}</p>
    <p>{`En contrepartie des Services rendus, ENIA prend une commission sur le prix total de la vente conclue. Le paiement de cette commission est
directement prélevé sur Stripe par ENIA lors de la transaction.`}</p>
    <h4>{`6.2. Prix des Produits`}</h4>
    <p>{`Le tarif des Produits proposés à la vente est exprimé en Euros H.T. sur la Fiche Produit et comprend la commission prise par ENIA. À ce prix,
seront ajoutés ou compris le cas échéant les frais de livraison et éventuelles taxes, ces frais seront présentés à l’Acheteur avant la validation de
la Commande.`}</p>
    <p>{`Le Fournisseur se réserve la faculté de modifier les prix à tout moment. Cependant, les Produits seront vendus au prix en vigueur à la date de
validation de la commande, sous réserve de disponibilité. En tout état de cause un tarif accepté par l’Acheteur ne pourra plus être modifié sauf à
annuler la commande.`}</p>
    <p>{`Le paiement par l’Acheteur sur la Plateforme s’effectue par carte bancaire via le module Stripe.`}</p>
    <p>{`Lors d’une vente ponctuelle, le paiement s’effectue comptant.`}</p>
    <p>{`Lors d’une vente récurrente (une seule vente correspondant à plusieurs livraisons), une fois l’Acheteur et le Fournisseur d’accord sur le tarif de
la vente récurrente, ENIA met alors en place un échéancier de paiement. En pareille hypothèse, en plus du paiement du prix prévu, un acompte
est payé par l’Acheteur dès la première commande, lequel est séquestré sur le compte Stripe d’ENIA jusqu’à la confirmation de la dernière vente
et sera restituée à la dernière livraison.`}</p>
    <p>{`En cas de non-respect d’une livraison par le Fournisseur, 20% du reste à livrer sera conservé par ENIA puis partagé avec l’Acheteur à proportion
de la commission prévue pour ENIA et dans la limite de la caution du Fournisseur.`}</p>
    <p>{`En cas de refus de prise en charge d’une livraison par l’Acheteur, 20% du montant du reste à livrer seront payés au Fournisseur en dédomma-
gement, et 80% seront restitués à l’Acheteur.`}</p>
    <p>{`L’Acheteur s’engage à ne pas demander auprès de sa banque suspension ou annulation du montant séquestré sur Stripe.
Les cartes bancaires acceptées sont indiquées sur la Plateforme. L’Acheteur garantit qu’il est pleinement habilité à utiliser le moyen bancaire
utilisé, qu’il en soit titulaire ou qu’il en ait reçu l’autorisation du titulaire et garantit que le moyen de paiement donne accès à des fonds suffisants.`}</p>
    <p>{`En cas de données erronées, de compte insuffisamment approvisionné, les coûts en résultant sont à la charge de l’Acheteur.
ENIA éditera et enverra à l’Acheteur la facture correspondant à l’achat des Produits comprenant les frais prélevés par ENIA lors de la vente.`}</p>
    <h4>{`6.3. Livraison test`}</h4>
    <p>{`Deux livraisons test payantes seront mises en place par le vendeur afin que l’acheteur puisse tester la matière et son taux de pouvoir méthano-
gène. Le contrat ne pourra pas commencer si ces deux livraisons n’ont pas été effectuées, et l’acheteur peut rompre le contrat si il n’est pas
satisfait de la qualité du produit.`}</p>
    <h3>{`Article 7. Mode de livraison`}</h3>
    <p>{`Le mode de livraison des Produits est précisé sur la Fiche Produit : il s’agit d’une livraison chez l’Acheteur ou d’une récupération par l’Acheteur
directement chez le Fournisseur. Une livraison par un tiers est également possible. La livraison ou la mise à disposition des Produits est
effectuée par le Fournisseur et ENIA n’est pas en charge ou responsable des conséquences d’une livraison.`}</p>
    <h4>{`7.1. Récupération`}</h4>
    <p>{`Les informations concernant le délai maximum de récupération, l’adresse et les horaires du Fournisseur sont précisées sur la Plateforme, et
pourra être ajustée directement entre le Fournisseur et l’Acheteur.`}</p>
    <h4>{`7.2. Livraison`}</h4>
    <p>{`Le délai d’expédition / livraison estimatif est indiqué à titre indicatif à l’Acheteur dans la Fiche Produit.`}</p>
    <p>{`Toutefois, si les Produits commandés n’ont pas été livrés à la date indicative ou, à défaut d’indication, plus de 30 jours après la validation de la
commande, l’Acheteur peut annuler sa commande par l’envoi d’une lettre recommandée avec accusé de réception à l’adresse du Fournisseur.`}</p>
    <p>{`Les livraisons s’effectuent exclusivement en France Métropolitaine et dans un rayon kilométrique défini, à l’adresse indiquée par l’Acheteur.`}</p>
    <p>{`L’Acheteur est seul responsable des conséquences d’un échec de livraison résultant de l’absence du destinataire, d’incapacité de livraison, d’une
adresse erronée.`}</p>
    <h4>{`7.3. Réception et réclamation`}</h4>
    <p>{`L’Acheteur est tenu de vérifier la marchandise, son contenu et sa conformité avec la Commande passée. Toute anomalie devra impérativement,
sous peine d’irrecevabilité́, être indiquée au Fournisseur le jour même de la réception du Produit.`}</p>
    <h3>{`Article 8. Rétractation – Résolution - Résiliation`}</h3>
    <h4>{`8.1. Le droit de rétractation`}</h4>
    <p>{`L’Acheteur Professionnel ne bénéficie pas du droit de rétractation.`}</p>
    <p>{`En raison de la nature périssable des Produits et impossibilité à récupérer les Produits une fois dans le méthaniseur, l’Acheteur non
professionnel ne bénéficie pas non plus du droit de rétractation au visa de l’article L.221-28 du code de la Consommation (le droit de rétractation ne peut
être exercé pour les contrats suivants : fourniture de biens susceptibles de se détériorer ou de se périmer rapidement et fourniture de biens qui,
après avoir été livrés et de par leur nature, sont mélangés de manière indissociable avec d'autres articles).`}</p>
    <h4>{`8.2. Résolution`}</h4>
    <p>{`L’Acheteur peut demander la résolution de la vente en cas de réception de Produits non conformes à la description. En tout état de cause,
l’Acheteur bénéficie à l’encontre du Fournisseur des garanties légales de conformité, des vices cachés et de la responsabilité du fait des
produits défectueux, conformément aux dispositions légales en vigueur, hors le cas où l’Acheteur n’a pas respecté les conditions d’utilisation ou de
conservation des Produits. Toute réclamation adressée à ENIA sera directement transmise au Fournisseur.`}</p>
    <h4>{`8.3. Résiliation`}</h4>
    <p>{`ENIA se réserve le droit d’interrompre le Service de l’Acheteur, si celui-ci constitue un danger pour le maintien de la sécurité de la Plateforme ou
de l'utilisation du Service à des fins non conformes aux présentes CG.`}</p>
    <h3>{`Article 9. Engagements et Responsabilité de l’Acheteur`}</h3>
    <h4>{`9.1. Dans le cadre de son Utilisation de la Plateforme`}</h4>
    <p>{`L’Acheteur s’engage à ne pas utiliser la Plateforme à d’autres fins que celles précisées aux CG. Ainsi, l'Acheteur s’engage à ne pas annuler une
vente conclue sur la Plateforme pour conclure ensuite cette vente en direct (hors la Plateforme) avec le Fournisseur.`}</p>
    <p>{`ENIA se réserve le droit d’éditer et/ou de supprimer tous comptes qui contreviendrait aux CG.`}</p>
    <p>{`Le Fournisseur est seul responsable de la description des Produits ; en cas d’erreur, ENIA n’est pas responsable d’une mise en relation erronée.`}</p>
    <p>{`En pareille hypothèse, seul le Fournisseur sera responsable et devra indemniser le cas échéant l’Acheteur.`}</p>
    <h4>{`9.2. Dans le cadre de son intervention sur la Plateforme`}</h4>
    <p>{`L’Acheteur s’engage à renseigner des informations complètes et véridiques sur son identité, adresse, etc. En cas d’erreur, il assumera seul les
éventuelles prestations complémentaires nécessaires.`}</p>
    <p>{`L’Acheteur s’engage sans que cette énumération puisse être considérée comme limitative, à ne diffuser aucun message ou information à
caractère injurieux, diffamatoire, raciste, xénophobe, portant atteinte à l’honneur ou la réputation d’autrui, incitant à la discrimination, à la haine d’une
personne ou d’un groupe de personnes en raison de leur origine ou de leur appartenance ou de leur non-appartenance à une ethnie, une nation,
une race ou une religion déterminée, menaçant une personne ou un groupe de personnes, à caractère pornographique ou pédophile, incitant à
commettre un délit, un crime ou un acte de terrorisme ou autre, portant atteinte aux droits d’autrui et à la sécurité des personnes et des biens.`}</p>
    <h4>{`9.3. Engagement concernant le paiement effectif`}</h4>
    <p>{`L'Acheteur s’engage à procéder au règlement du prix annoncé et ne pas demander l’annulation de l’autorisation de prélèvement.`}</p>
    <h4>{`9.4. Responsabilité`}</h4>
    <p>{`L’Acheteur est seul responsable des dommages et préjudices directs ou indirects, matériels ou immatériels, causé au Fournisseur et à tout autre
tiers du fait du Contenu qu’il met en ligne sur la Plateforme.`}</p>
    <h3>{`Article 10. Responsabilité d’ENIA`}</h3>
    <h4>{`10.1. En tant qu’hébergeur`}</h4>
    <p>{`ENIA en tant qu’éditeur de la Plateforme a une responsabilité d’hébergeur et se doit de retirer toute annonce / Fiche Produit à caractère
manifestement illicite, et signalée comme telle. ENIA ne saurait être tenue responsable, a priori et sans signalement de ce contenu, de tout contenu
illicite publié par un Utilisateur.`}</p>
    <p>{`En cas de mise à disposition auprès de l’Acheteur de moyens d'évaluation des Fournisseur à l'issue de la réception des Produits, ENIA n'assure
aucun contrôle de l'appréciation réalisée et se contente de stocker l’évaluation sur la Plateforme. ENIA peut toutefois être amenée à supprimer
toute appréciation dont le contenu lui aurait été signalé comme étant illicite.`}</p>
    <h4>{`10.2. Concernant la relation contractuelle entre le Fournisseur et l’Acheteur`}</h4>
    <p>{`ENIA n’intervient pas dans la relation contractuelle entre le Fournisseur et l’Acheteur et n’est qu’un intermédiaire. ENIA met à leur disposition des
outils et moyens techniques leur permettant d’entrer en relation ainsi que d’autres prestations de services connexes (comptabilité, logistique,
etc.). Sa responsabilité se limite à la fourniture de ces services et à la mise en relation.`}</p>
    <p>{`ENIA publie de bonne foi sur la Plateforme les éléments et informations fournis par le Fournisseur et l’Acheteur et ne peut être tenue pour
responsable de l'inexactitude de ces informations. La responsabilité qui pèse sur le Fournisseur ou l’Acheteur ne peut être en aucun cas imputée à
ENIA.`}</p>
    <p>{`ENIA s’engage néanmoins à transmettre au Fournisseur toute réclamation ou plainte formulée par l’Acheteur.`}</p>
    <h4>{`10.3. Concernant le transfert d’argent`}</h4>
    <p>{`Pour la sécurité des transactions, ENIA a choisi de confier les tâches de collecte et de conservation éventuelle de données à un prestataire en
matière de paiement certifié PCI-DSS, dénommé Stripe. Cette norme est un standard de sécurité international assurant la confidentialité et
l’intégrité des données bancaires des porteurs de cartes. ENIA ne saurait être tenu responsable des modifications, erratums, etc. susceptibles
d'apparaître dans les informations diffusées par Stripe.`}</p>
    <h4>{`10.4. Responsabilité`}</h4>
    <p>{`La responsabilité d’ENIA ne pourra en aucun cas être engagée au titre de tout dommage indirect, tel que les pertes de chiffre d’affaires ou
bénéfice, pertes ou altérations des données, atteinte à l’image, etc.`}</p>
    <h3>{`Article 11. Force majeure`}</h3>
    <p>{`Aucune partie ne pourra voir sa responsabilité engagée pour tout dommage causé par un fait de force majeure, par le Fournisseur, un Acheteur
ou par un tiers.`}</p>
    <p>{`Sont notamment considérés comme cas de force majeure, outre ceux retenus par la jurisprudence, le blocage des télécommunications, la grève
totale ou partielle, lock-out, émeute, trouble civil, épidémie, blocage des moyens de transport ou d’approvisionnement, incendie, tempête,
inondation, dégâts des eaux, restrictions gouvernementales ou légales et tout autre cas imprévisible, irrésistible et indépendant de la volonté des
Parties empêchant l’exécution normale de leurs obligations réciproques.`}</p>
    <h3>{`Article 12. Utilisation loyale de la Plateforme`}</h3>
    <p>{`L’Acheteur s’engage à faire une utilisation loyale de la Plateforme et s’interdit de contourner la Plateforme.`}</p>
    <h3>{`Article 13. Loi applicable - Règlement des litiges`}</h3>
    <p>{`Les CG sont régies par et soumises au droit français. Sauf dispositions d’ordre public, tout litige relatif à leur interprétation et/ou à leur exécution relève des juridictions françaises.`}</p>
    <p>{`Tout différend né de l’interprétation ou exécution des CG ou toute réclamation fera l’objet d’une tentative de règlement amiable avant toute
action judiciaire : soit directement entre les Parties, soit par l’intermédiaire d’un médiateur.`}</p>
    <p>{`A défaut de solution amiable intervenue, le litige sera soumis aux tribunaux compétents, selon les dispositions légales en vigueur.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      